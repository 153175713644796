<!--
  PACKAGE_NAME : src/components/common
  FILE_NAME : license-tool-tip.vue
  AUTHOR : devyoon91
  DATE : 2024-08-14
  DESCRIPTION : 계정 라이센스 툴팁 컴포넌트
-->
<template>
  <div class="text-right">
    <button
      class="mdi mdi-help-circle"
      id="licenseTooltip"
      @mouseenter="setTooltips('license')"
      @mouseleave="setTooltips('license')"
    ></button>
    <DxPopover
      target="#licenseTooltip"
      :visible="tooltip.isVisible.license"
      :close-on-outside-click="tooltip.closeOnOutsideClick"
    >
      <span v-html="tooltip.contents"></span>
    </DxPopover>

    라이센스 보유 현황 : {{ memberLicenseCnt.toLocaleString() }} /
    {{ getSystemMemberLicenseCnt.toLocaleString() }}
  </div>
</template>

<script>
  import { DxPopover } from 'devextreme-vue/popover';

  export default {
    components: { DxPopover },
    props: {},
    data() {
      return {
        memberLicenseCnt: 0,
        tooltip: {
          isVisible: {
            license: false,
          },
          closeOnOutsideClick: false,
          contents: `계약 하에 할당된 라이선스 개수를 초과하여 계정을 생성할 수 없습니다.
                        <br/>
                        - 휴면/잠금 계정은 라이선스 계정 수에 포함
                        <br/>
                        - 삭제 계정은 미포함`,
        },
      };
    },
    computed: {
      /** @description: 시스템 설정의 유저 라이센스 개수 */
      getSystemMemberLicenseCnt() {
        return Number(this.$_getSystemData('member_license_cnt').configValue);
      },
    },
    methods: {
      /** @description: 라이센스 수 툴팁 이벤트 */
      setTooltips(key) {
        this.tooltip.isVisible[key] = !this.tooltip.isVisible[key];
      },
      /**
       * @description 유저 라이센스 개수 가져오기
       * @return {Promise<void>}
       */
      async getMemberLicenseCnt() {
        // 유저 라이센스 개수 가져오기
        this.memberLicenseCnt = await this.$_getMemberLicenseCnt();
      },
      /**
       * @description 유저 라이센스 개수 초기화
       */
      async resetLicenseCnt() {
        await this.getMemberLicenseCnt();
      },
      /**
       * @description 남은 라이센스 개수
       * @return {Promise<T>}
       */
      async leftLicenseCnt() {
        return this.getMemberLicenseCnt().then(() => {
          return this.getSystemMemberLicenseCnt - this.memberLicenseCnt;
        });
      },
      /**
       * 라이센스 사용 가능 여부
       *
       * @return {Promise<boolean>}
       */
      async isLicenseAvailable() {
        const leftLicenseCnt = await this.leftLicenseCnt();
        return leftLicenseCnt > 0;

      },
    },
    created() {},
    mounted() {
      this.getMemberLicenseCnt();
    },
  };
</script>

<style lang="scss" scoped></style>